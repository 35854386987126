import React from "react"
import MetaHead from "../components/MetaHead";
import Layout from "../components/Layout";
import WorkPost from "../components/WorkPost"
import style from "../styles/Work.module.scss"
import {graphql} from "gatsby"


const Work = ({data, pageContext, path}) => {
	const articles = data.allContentfulBlogPost.nodes
	const essentialData = data.contentfulEssentialData
	
	return (
		<>
		<MetaHead meta={data.contentfulMetaFields} lang={pageContext.langKey} path={path} />
		<Layout essentialData={{...essentialData, ...pageContext, path}}>
			<h1>{essentialData.workTitle}</h1>
			<div className={style.work_wrapper}>
				{
					articles.map((blog_post) => {
						return (
							<WorkPost 
								key={blog_post.slug}
								uid={blog_post.slug}
								title={blog_post.title}
								subtitle={blog_post.subtitle.subtitle}
								image={blog_post.workThumbnail}
								langKey={pageContext.langKey}
								/>
						)
					})
				}
			</div>
		</Layout>
		</>
	);
}
 
export const query = graphql`
query($langKey: String!){
	allContentfulBlogPost(filter: {work: {eq: true}, node_locale: {eq: $langKey}}) {
		nodes {
			workThumbnail {
				fluid{
					...GatsbyContentfulFluid
				}
			}
			title
			subtitle {
				subtitle
			}
			slug
		}
	}
	contentfulEssentialData(node_locale: {eq: $langKey}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
	contentfulMetaFields(identifier: {eq: "work"}, node_locale: {eq: $langKey}) {
		title
		ogType
		description {
			description
		}
	}
}`

export default Work;

